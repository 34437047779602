#explication h1, #explication h2, #explication h3, #explication h4, #explication h5, #explication h6,
#explication p, #explication blockquote, #explication ul,
#explication fieldset, #explication form,
#explication ol, #explication dl, #explication dir,
#explication menu {
  margin: 0.25em 0;
}

#codes-phologiques {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
}

.img-codes {
  max-width: 420px;
  width: 100%;
  height: auto;
}

