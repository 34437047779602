#explication {
  h1, h2, h3, h4, h5, h6,
  p, blockquote, ul,
  fieldset, form,
  ol, dl, dir,
  menu {
    margin: .25em 0
  }
}

#codes-phologiques{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
}

.img-codes{
  max-width: 420px;
  width: 100%;
  height: auto;
}